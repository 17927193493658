/* eslint-disable camelcase */
import React from 'react';

const ArtistAvatar = ({alt, src, url, galleryInfo}: propTypes) => {
  const displayLink = () => (
    <div className="avatar-container">
      <a href={url!} className="artist-page-link">
        <img loading="lazy" className="artist-avatar" alt={alt} src={src}></img>
      </a>
      {galleryInfo?.represented_by_gallery && (
        <div className="representedByGallery">by {galleryInfo.gallery_name}</div>
      )}
    </div>
  );

  const avatar = () => {
    return galleryInfo?.represented_by_gallery ? (
      <a
        className="avatar-link"
        title={`Represented by ${galleryInfo.gallery_name}`}
        target="_blank"
        href={galleryInfo.gallery_url}
      >
        <img loading="lazy" className="artist-avatar" alt={alt} src={src}></img>
      </a>
    ) : (
      <img loading="lazy" className="artist-avatar" alt={alt} src={src}></img>
    );
  };

  const displayImage = () => (
    <div className="avatar-container">
      {avatar()}
      {galleryInfo?.represented_by_gallery && (
        <a
          className="representedByGallery"
          title={`Represented by ${galleryInfo.gallery_name}`}
          target="_blank"
          href={galleryInfo.gallery_url}
        >
          by {galleryInfo.gallery_name}
        </a>
      )}
    </div>
  );

  return <>{url ? displayLink() : displayImage()}</>;
};

interface propTypes {
  alt: string;
  src: string;
  url?: string;
  galleryInfo?: {
    gallery_id: number;
    represented_by_gallery: boolean;
    gallery_name: string;
    gallery_url: string;
  };
}

export default ArtistAvatar;
