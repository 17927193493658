import axios from 'axios';
import constantsFactory from '../../src/utils/constants';

const {API} = constantsFactory();
const API_BASE_URL = process.env.API_BASE;

// Action Types
import {
  STATUS_SUCCEEDED,
  STATUS_LOADING,
  STATUS_UPDATING,
  SET_CUSTOMER_INFO,
  SET_FAVOURITES_ARRAY,
  SET_FAVOURITE_COUNT,
  FAVOURITE_INCREMENT,
  FAVOURITE_DECREMENT,
  FAVOURITE_TOGGLE,
  ADD_TO_COLLECTION,
  REMOVE_FROM_COLLECTION,
  SET_ARTIST_ID,
  LOAD_SHIPPING_DATA_REQUEST,
  LOAD_SHIPPING_DATA_SUCCESS,
  LOAD_SHIPPING_DATA_FAILURE,
  CLEAR_SHIPPING_DATA,
  LOAD_AUTO_DETECTED_TAGS_REQUEST,
  LOAD_AUTO_DETECTED_TAGS_SUCCESS,
  LOAD_AUTO_DETECTED_TAGS_FAILURE,
  GET_RECENTLY_VIEWED_SUCCESS,
  GET_RECENTLY_VIEWED_REQUEST,
  GET_RECENTLY_VIEWED_FAILURE,
  GET_MORE_BY_SAME_ARTIST_REQUEST,
  GET_MORE_BY_SAME_ARTIST_SUCCESS,
  GET_MORE_BY_SAME_ARTIST_FAILURE,
  GET_PRODUCT_BUNDLE_REQUEST,
  GET_PRODUCT_BUNDLE_SUCCESS,
  GET_PRODUCT_BUNDLE_FAILURE,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  SET_FAVOURITE_REQUEST,
  SET_FAVOURITE_SUCCESS,
  SET_FAVOURITE_FAILURE,
} from '../actionTypes';
import paramsHelper from '../../src/utils/paramsHelper';

export const getProduct = (slug, successCallbacks = []) => {
  return {
    types: [GET_PRODUCT_REQUEST, GET_PRODUCT_SUCCESS, GET_PRODUCT_FAILURE],
    callAPI: () =>
      axios.get(`${API.PRODUCT}${slug}/full/`, {
        headers: {Accept: 'application/json'},
        withCredentials: true,
      }),
    onSuccess: successCallbacks,
  };
};

// private actions
const setFavouritesArray = (favouritesArray) => ({
  type: SET_FAVOURITES_ARRAY,
  payload: favouritesArray,
});

const setCustomerFavourites = (productId) => {
  const URL = `${API_BASE_URL}${API.COLLECTIONS_API}`;
  favArray.push({id: productId});
  return (dispatch) => {
    axios
      .post(URL, {
        action: 'favourite',
        product_id: productId,
      })
      .catch((err) => {
        console.log('AXOIS SET_FAVOURITES ERROR:', err);
      });
  };
};

export const setFavourite = (
  productId,
  onSuccessCallbacks = [],
  onErrorCallbacks = [],
  cleanupCallbacks = [],
) => {
  return {
    // Types of actions to emit before and after
    types: [SET_FAVOURITE_REQUEST, SET_FAVOURITE_SUCCESS, SET_FAVOURITE_FAILURE],
    // Perform the fetching:
    callAPI: () =>
      axios.post(
        `${API_BASE_URL}${API.COLLECTIONS_API}`,
        {action: 'favourite', product_id: productId},
        {
          headers: {Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'},
          withCredentials: true,
        },
      ),
    // Functions to run on success
    onSuccess: onSuccessCallbacks,
    // Functions to run to on cleanup
    onCleanup: cleanupCallbacks,
    // Functions to run on Error
    onError: onErrorCallbacks,
  };
};

// global actions

export const getCustomerFavourites = (artistId, isAuth) => {
  return (dispatch) => {
    if (isAuth.isAuthenticated()) {
      const URL = `${process.env.HOST}${API.FAVOURITES}`;
      const favArray = [];
      axios
        .get(URL, {artist_id: artistId})
        .then((res) => {
          res.data.forEach((elem) => {
            favArray.push(elem.id);
          });
          dispatch(setFavouritesArray(favArray));
        })
        .catch((err) => {
          console.log('AXOIS GET_FAVOURITES ERROR:', err);
        });
    } else {
      return [];
    }
  };
};

export const getCustomerInfo = (productSlug) => {
  return (dispatch) => {
    const URL = `${API_BASE_URL}/api/product/${productSlug}/customer_info/`;
    axios
      .get(URL)
      .then((res) => {
        const payload = {
          id: res.data.id,
          favourited: res.data.is_favourited,
          collections: res.data.collections,
          freeShipping: res.data.has_free_shipping,
          offerPricing: res.data.offer_pricing,
        };

        dispatch(setCustomerInfo(payload));
      })
      .catch((err) => {
        console.log('AXOIS GET_CUSTOMER_INFO ERROR:', err);
      });
  };
};

export const setArtistId = (artistId) => ({
  type: SET_ARTIST_ID,
  payload: artistId,
});

// Action Creator
export const setCustomerInfo = (payload) => ({
  type: SET_CUSTOMER_INFO,
  payload: payload,
});

export const setFavouriteCount = (favouriteCount) => ({
  type: SET_FAVOURITE_COUNT,
  payload: {favouriteCount: favouriteCount},
});

export const favouriteIncrement = () => ({type: FAVOURITE_INCREMENT});

export const favouriteDecrement = () => ({type: FAVOURITE_DECREMENT});

export const favouriteToggle = () => ({type: FAVOURITE_TOGGLE});

export const addToCollection = (addNumber) => ({
  type: ADD_TO_COLLECTION,
  payload: {addNumber: addNumber},
});

export const removeFromCollection = (removeNumber) => ({
  type: REMOVE_FROM_COLLECTION,
  payload: {removeNumber: removeNumber},
});

export const getShippingInfo = ({
  slug,
  type,
  successCallbacks,
  errorCallbacks,
  cleanupCallbacks,
}) => ({
  types: [LOAD_SHIPPING_DATA_REQUEST, LOAD_SHIPPING_DATA_SUCCESS, LOAD_SHIPPING_DATA_FAILURE],
  shouldCallAPI: (state) => {
    return state.product.status !== STATUS_LOADING;
  },
  callAPI: () =>
    axios.get(`${API.PRODUCT}${slug}/shipping/`, {
      headers: {Accept: 'application/json'},
      withCredentials: true,
    }),
  // Retrieve from cache
  callCache: (state) => {
    if (type === 'original') {
      if (
        Object.keys(state.product.data.shipping[type]).length === 0 ||
        state.product.data.shipping[type]['shipping_to'] === null
      )
        return false;
    } else {
      if (
        Object.keys(state.product.data.shipping[type]).length === 0 ||
        !state.product.data.shipping[type][slug]
      )
        return false;
    }
    return {
      data:
        type === 'original'
          ? state.product.data.shipping[type]
          : state.product.data.shipping[type][slug ?? 1],
    };
  },
  payload: {slug, productType: type},
  // Functions to run on success
  onSuccess: successCallbacks,
  // Functions to run to on cleanup
  onCleanup: cleanupCallbacks,
  // Functions to run on Error
  onError: errorCallbacks,
});

export const clearShippingInfo = () => ({type: CLEAR_SHIPPING_DATA});

export const getAutoTags = (slug) => ({
  types: [
    LOAD_AUTO_DETECTED_TAGS_REQUEST,
    LOAD_AUTO_DETECTED_TAGS_SUCCESS,
    LOAD_AUTO_DETECTED_TAGS_FAILURE,
  ],
  callAPI: () =>
    axios.post(
      `${API.PRODUCT}${slug}/update-auto-tags/`,
      {},
      {
        headers: {Accept: 'application/json', 'x-api-key': process.env.API_AUTH_X_API_KEY},
        withCredentials: true,
      },
    ),
});

export const getRecentlyViewed = (productId, successCallbacks = []) => {
  const MLT_IMAGE_SIZE = '270x270^';
  const params = {
    product_id: productId,
    limit: 10,
    image_size: MLT_IMAGE_SIZE,
    crop_images: 1,
  };
  const query = paramsHelper(params).objectToQueryString();

  return {
    types: [GET_RECENTLY_VIEWED_REQUEST, GET_RECENTLY_VIEWED_SUCCESS, GET_RECENTLY_VIEWED_FAILURE],
    callAPI: () =>
      axios.get(`${API.RECENTLY_VIEWED}${query}`, {
        headers: {Accept: 'application/json'},
        withCredentials: true,
      }),
    onSuccess: successCallbacks,
  };
};

export const getMoreBySameArtist = (mainProductSlug, successCallbacks) => {
  const imgSize = '270x270^';
  const params = {
    limit: 10,
    image_size: imgSize,
    crop_images: 1,
  };
  const query = paramsHelper(params).objectToQueryString();

  return {
    types: [
      GET_MORE_BY_SAME_ARTIST_REQUEST,
      GET_MORE_BY_SAME_ARTIST_SUCCESS,
      GET_MORE_BY_SAME_ARTIST_FAILURE,
    ],
    callAPI: () =>
      axios.get(`${API.PRODUCT}${mainProductSlug}/artist/products/${query}`, {
        headers: {Accept: 'application/json'},
        withCredentials: true,
      }),
    onSuccess: successCallbacks,
  };
};

export const getBundle = (productSlug, successCallbacks) => {
  return {
    types: [GET_PRODUCT_BUNDLE_REQUEST, GET_PRODUCT_BUNDLE_SUCCESS, GET_PRODUCT_BUNDLE_FAILURE],
    callAPI: () =>
      axios.get(`${API.PRODUCT}${productSlug}/to-bundle/`, {
        headers: {Accept: 'application/json'},
        withCredentials: true,
      }),
    onSuccess: successCallbacks,
  };
};
