import React, {useRef, useEffect, useState} from 'react';

import {multipart} from '../../services/artfinder-service';
import {on, off, emit} from '../../utils/events';
import useAuth from '../../hooks/use-auth/use-auth';
import useModal from '../../hooks/use-modal/use-modal';
import constantsFactory from '../../utils/constants';
const {EVENTS} = constantsFactory();

import Loading from '../loading/loading';
import Button from '../button/button';
import {H5} from '../typography/typography';

const ArtistContactMe = ({artist}) => {
  const target = useRef(null);
  const modal = useModal(target);
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError]: any = useState(null);
  const [msgSubject, setMsgSubject]: any = useState('');

  useEffect(() => {
    const open = () => {
      if (auth.isAuthenticated()) {
        modal.open();
      } else {
        emit('CLICK_ON_JOIN');
      }
    };

    on(EVENTS.CLICK_CONTACT_ARTIST, open);
    on(EVENTS.CLICK_CONTACT_ARTIST, (msg) => {
      if (typeof msg === 'string' || msg instanceof String) {
        setMsgSubject(msg);
      } else {
        setMsgSubject('');
      }
    });
    return () => {
      off(EVENTS.CLICK_CONTACT_ARTIST, open);
      off(EVENTS.CLICK_CONTACT_ARTIST, setMsgSubject);
    };
  }, [auth, modal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    setError(null);
    const form = e.target;
    multipart('/messages/write/', form)
        .then(() => {
          form.reset();
          modal.close();
          emit(
              'OPEN_CONFIRMATION_MSG',
              'Your message has been sent successfully',
          );
        })
        .catch(() => {
          setError({message: 'Server error'});
        })
        .finally(() => {
          setLoading(false);
        });
  };

  return (
    <div
      ref={target}
      className="modal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            {loading && <Loading />}
            <div className="modal-header">
              <H5 style="art">Contact {artist.name}</H5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error.message}
                </div>
              )}

              <input type="hidden" name="recipients" value={artist.username} />

              <div className="form-group">
                <label htmlFor="artists-contact-subject">Subject *</label>
                <input
                  type="text"
                  className="form-control"
                  id="artists-contact-subject"
                  name="subject"
                  defaultValue={msgSubject}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="artists-contact-body">Your message *</label>
                <textarea
                  className="form-control"
                  id="artists-contact-body"
                  rows={3}
                  name="body"
                  required
                ></textarea>
              </div>

              <div className="form-group">
                <label htmlFor="artists-contact-attachment">Attachments</label>
                <input
                  name="attachment"
                  type="file"
                  multiple
                  className="form-control-file"
                  id="artists-contact-attachment"
                />
              </div>
            </div>
            <div className="modal-footer">
              <Button secondary dataDismiss="modal">
                Cancel
              </Button>

              <Button submit>Send</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ArtistContactMe;
