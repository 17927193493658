import React, {useRef, useEffect, useState} from 'react';

import _ from '../../utils/_';
import {post} from '../../services/artfinder-service';
import {on, off, emit} from '../../utils/events';
import useAuth from '../../hooks/use-auth/use-auth';
import useModal from '../../hooks/use-modal/use-modal';
import constantsFactory from '../../utils/constants';
const {EVENTS} = constantsFactory();

import Button from '../button/button';
import Loading from '../loading/loading';
import {H5, P} from '../typography/typography';

const readAsDataURL = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const readFiles = (xs: FileList) => {
  const fileArray: File[] = [];
  for (let i = 0; i < xs.length; i++) {
    fileArray.push(xs[i]);
  }
  return Promise.all(fileArray.map(readAsDataURL));
};

const formDefault = () => ({
  budget: '',
  description: '',
  size: '',
  ideal_finish_date: '',
  attachments: '',
  terms_and_conditions: true,
});

const ArtistCommission = ({artist}) => {
  const target = useRef(null);
  const modal = useModal(target);
  const auth = useAuth();
  const [form, setForm] = useState(formDefault());
  const [loading, setLoading] = useState(false);
  const [error, setError]: any = useState(null);

  useEffect(() => {
    const open = () => {
      if (auth.isAuthenticated()) {
        modal.open();
      } else {
        emit('CLICK_ON_JOIN');
      }
    };

    on(EVENTS.CLICK_COMMISSION_ARTIST, open);
    return () => {
      off(EVENTS.CLICK_COMMISSION_ARTIST, open);
    };
  }, [auth, modal]);

  const handleChange = (name) => (e) => {
    setForm(_.assoc(name, e.target.value, form));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    setError(null);
    const payload = Object.fromEntries(
        Object.entries(form).filter(([key, v]) => {
          if (key === 'ideal_finish_date') {
            if (v === '' || v === null) {
              return false;
            }
          }
          return true;
        }),
    );
    readFiles(e.target.attachments.files || e.dataTransfer.files).then((xs) => {
      post(
          '/api/commission/',
          _.merge({artist: artist.id, product: null, attachments: xs}, payload),
      )
          .then(() => {
            setForm(formDefault());
            modal.close();
            emit(
                'OPEN_CONFIRMATION_MSG',
                'Your commission request has been sent successfully',
            );
          })
          .catch((error) => {
            const errorResponse = Object.values(error)[0];
          Array.isArray(errorResponse) && errorResponse[0] ?
            setError({message: errorResponse[0]}) :
            setError({message: 'Server error'});
          })
          .finally(() => {
            setLoading(false);
          });
    });
  };

  return (
    <div
      ref={target}
      className="modal artist-commission"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            {loading && <Loading />}
            <div className="modal-header">
              <H5 style="art">Commission {artist.name}</H5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <a href="/commissions/">Find out more</a>
              </div>

              {error && (
                <div className="alert alert-danger" role="alert">
                  {error.message}
                </div>
              )}

              <div className="form-group">
                <label htmlFor="artists-commission-budget">Budget *</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="artists-commission-budget-prepend"
                    >
                      £
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="artists-commission-budget"
                    aria-describedby="artists-commission-budget-prepend"
                    value={form.budget}
                    onChange={handleChange('budget')}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="artists-commission-description">
                  Description *
                </label>
                <textarea
                  className="form-control"
                  id="artists-commission-description"
                  rows={3}
                  value={form.description}
                  onChange={handleChange('description')}
                  required
                ></textarea>
              </div>

              <div className="form-group">
                <label htmlFor="artists-commission-size">Size (optional)</label>
                <input
                  type="text"
                  className="form-control"
                  id="artists-commission-size"
                  value={form.size}
                  onChange={handleChange('size')}
                />
              </div>

              <div className="form-group">
                <label htmlFor="artists-commission-finish-date">
                  Ideal finish date (optional)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="artists-commission-finish-date"
                  placeholder="2020-09-17"
                  value={form.ideal_finish_date}
                  onChange={handleChange('ideal_finish_date')}
                />
              </div>

              <div className="form-group">
                <label htmlFor="artists-commission-attachments">
                  Attachments
                </label>
                <input
                  name="attachments"
                  type="file"
                  multiple
                  className="form-control-file"
                  id="artists-commission-attachments"
                />
              </div>

              <div className="form-check form-check-checkbox">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="artists-commission-terms"
                  required
                />
                <label
                  className="form-check-label"
                  htmlFor="artists-commission-terms"
                >
                  I agree to{' '}
                  <a href="/help/commissions-customer-terms/">
                    Customer Terms and Conditions for Commissions
                  </a>
                </label>
              </div>

              <P style="p1">
                By submitting this form you do not commit to follow through with
                the commission.
              </P>
            </div>
            <div className="modal-footer">
              <Button secondary dataDismiss="modal">
                Cancel
              </Button>

              <Button submit>Send</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ArtistCommission;
